import React from 'react';
import {Box} from 'rebass'

/**
 * @type { fill: string | any }
 */
interface ILogoProps {
    fill: string | any
}

/**
 * Component overrides { Logo }
 * Size props range: 25-35px (recommended)
 * @typeof fill toggles state of svg mask inversion
 * @interface ILogoProps
 */
class Logo extends React.Component<ILogoProps> {
    public render() {
        return (
            <Box
                style={{
                    width: '50%',
                    transform: 'scale(0.5)',
                    transformOrigin: 'center left',
                }}
            >
                <svg width="252" height="181" viewBox="0 0 252 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M111.762 116.523L111.598 139H108.316L54.8319 65.0078H54.3397L54.1757 113.734V139H50.8944V115.047L51.0585 58.4453H54.3397L107.824 132.437H108.48L108.644 58.6094L111.926 58.2812L111.762 116.523ZM191.314 23.0078L192.955 24.6484L192.791 25.4687C185.244 33.0156 177.369 40.3984 169.166 47.6172L168.345 47.4531L166.541 45.3203C174.087 38.9766 182.072 31.4844 190.494 22.8437L191.314 23.0078ZM203.783 110.125C204.001 119.422 201.486 126.641 196.236 131.781C190.986 136.922 183.548 139.492 173.923 139.492C164.189 139.492 156.751 136.977 151.611 131.945C146.58 126.805 144.119 119.531 144.228 110.125L144.392 98.3125V58.4453H147.837L147.509 109.961C147.4 118.492 149.587 125.055 154.072 129.648C158.666 134.133 165.283 136.375 173.923 136.375C182.564 136.375 189.126 134.133 193.611 129.648C198.205 125.055 200.501 118.492 200.501 109.961V58.4453H203.783V110.125Z" fill="url(#paint0_linear)"/>
                    <defs>
                        <linearGradient id="paint0_linear" x1="0" y1="90.2344" x2="251.562" y2="90.2344" gradientUnits="userSpaceOnUse">
                            <stop stopColor={this.props.fill ?? "#FF5F6D"}/>
                            <stop offset="1" stopColor={this.props.fill ?? "#FFC371"}/>
                        </linearGradient>
                    </defs>
                </svg>
            </Box>
        )
    }
}

export default Logo;